<template>
  <Evaluation
    :id="attemptId"
    :key="attemptId"
    :title="$t('app.attempts')"
    endpoint="teaching.evaluations.attempt"
    :type="evaluationTypes.OBSERVATION"
    hide-feedback
  >
    <template #title>
      <VTitle :title="$t('app.attempts')" class="section-title" />
    </template>

    <template #card-title="{ resource }">
      <div class="flex items-center">
        <VImage
          :src="
            getAvatarURL(resource?.registration?.learner?.avatar?.view_path)
          "
          :name="
            `${resource?.registration?.learner?.firstname} ${resource?.registration?.learner?.firstname}`
          "
          class="mr-2"
        />

        <div>
          <div class="font-medium">
            {{ resource?.registration?.learner?.firstname }}
            {{ resource?.registration?.learner?.lastname }}
          </div>

          <div class="text-theme-7 font-light whitespace-nowrap text-xs">
            @{{ resource?.registration?.learner?.username }}
          </div>
        </div>
      </div>
    </template>
  </Evaluation>
</template>

<script>
// Composables
import useDisplay from "@/composables/useDisplay";
// Components
import Evaluation from "@/views/learning/evaluations/Evaluation";
import VImage from "@/components/VImage";
import VTitle from "@/components/VTitle";
// Constants
import evaluationTypes from "@/constants/evaluationTypes";

export default {
  components: {
    Evaluation,
    VTitle,
    VImage
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    attemptId: {
      type: [Number, String],
      required: true
    }
  },
  setup() {
    const { getAvatarURL } = useDisplay();

    return {
      evaluationTypes,
      // useDisplay
      getAvatarURL
    };
  }
};
</script>
